import styled from "styled-components"

import { AdjustableLocationMap } from "src/components/Homes/HomeAddress/AdjustableHomeMap"
import { MissingAddressBanner } from "src/components/Homes/HomeDetails/HomeSettings/HomeAddress"
import {
  formatPostalAddress,
  formatStreetname,
} from "src/components/Homes/HomeDetails/Overview/addressUtils"
import { THomeGroup } from "src/data/homeGroups/types/homeGroupTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { getAccessLogic } from "src/data/user/logic/accessLogic"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { MButton } from "src/ui/Button/MButton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function HomeGroupAddress({
  homeGroupId,
  homeGroupAddress,
  homeGroupLocation,
  homeGroupRole,
}: {
  homeGroupId: THomeGroup["id"]
  homeGroupAddress: THomeGroup["address"]
  homeGroupLocation: THomeGroup["location"]
  homeGroupRole: THomeGroup["user_role"]
}) {
  const { t, langKeys, tPlaceholder } = useTranslate()
  const { org } = useOrganization()

  const isOrgAdmin = getAccessLogic({ role: org.user_role }).hasAdminAccess
  const editable = isOrgAdmin || homeGroupRole === "admin"

  const address = homeGroupAddress
  const { latitude = 0, longitude = 0 } = homeGroupLocation ?? {}

  return (
    <div>
      <MText variant="heading3" marginBottom={spacing.M}>
        {tPlaceholder("Location address")}
      </MText>

      {address && (
        <>
          <MText variant="subtitle" color="secondary">
            {formatStreetname(address)}
          </MText>
          <MText color="secondary">{formatPostalAddress(address)}</MText>
        </>
      )}

      {!address && <MissingAddressBanner />}

      {!!latitude && !!longitude && (
        <MapWrapper>
          <AdjustableLocationMap
            showTooltip={false}
            showAdjustButton={false}
            height="200px"
            markerWidth="30px"
            location={{ latitude, longitude }}
          />
        </MapWrapper>
      )}

      <ButtonWrapper>
        <MButton
          variant="subtle"
          disabled={!editable}
          appHref={Routes.HomeGroupAddress.location(homeGroupId)}
        >
          {t(langKeys.home_change_address)}
        </MButton>
      </ButtonWrapper>
    </div>
  )
}

const MapWrapper = styled.div`
  max-width: 600px;
  margin-block: ${spacing.M};
`

const ButtonWrapper = styled.div`
  margin-top: ${spacing.M};
`
